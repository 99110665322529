import { DateTime } from 'luxon'

export const parsePost = (p) => {
  let category = {}
  if (p.attributes.category?.data) {
    category = {
      id: p.attributes.category?.data.id,
      name: p.attributes.category?.data?.attributes?.name,
    }
  }
  return {
    id: p.id,
    title: p.attributes?.title,
    slug: p.attributes?.slug,
    excerpt: p.attributes?.excerpt,
    author: p.attributes?.author,
    image: p.attributes?.image,
    date: DateTime.fromISO(p.attributes?.date).toFormat('MMMM dd, yyyy'),
    category,
    externalLink: p.attributes?.externalLink,
  }
}
