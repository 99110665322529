
import PostLink from '@/components/parts/news/PostLink.vue'
export default {
  components: {
    PostLink,
  },
  props: {
    posts: {
      type: Array,
      default: () => {},
    },
  },
}
