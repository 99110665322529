import { gql } from 'graphql-tag'

import { ImageFragment, LinkFragment } from './fragments/parts.js'

export default gql`
  query Posts($pageSize: Int, $page: Int, $id: ID) {
    posts(sort: "date:desc", pagination: { pageSize: $pageSize, page: $page }, filters: { not: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          title
          slug
          externalLink {
            ...LinkFragment
          }
          excerpt
          author
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          date
          image {
            ...ImageFragment
          }
        }
      }
      meta {
        pagination {
          total
          pageCount
        }
      }
    }
  }

  ${ImageFragment}
  ${LinkFragment}
`
