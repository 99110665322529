
import postsQuery from '@/apollo/queries/posts'
import { parsePost } from '@/utils/parse-post.js'
import NewsGrid from '@/components/parts/news/NewsGrid.vue'
import NewsDetail1SVG from '@/components/svg/news/NewsDetail1SVG.vue'
import NewsDetail3SVG from '@/components/svg/news/NewsDetail3SVG.vue'
import BtnSecondary from '@/components/parts/button/BtnSecondary.vue'
import ArrowRightSVG from '@/components/svg/icon/ArrowRightSVG.vue'
export default {
  components: {
    NewsGrid,
    NewsDetail1SVG,
    NewsDetail3SVG,
    BtnSecondary,
    ArrowRightSVG,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      posts: [],
      tl: null,
    }
  },

  async fetch() {
    const client = this.$apollo.getClient()
    const count = 4

    const query = {
      query: postsQuery,
      variables: { pageSize: count, page: 0 },
    }
    try {
      const apiData = await client.query(query)
      this.$nextTick(() => {
        this.parsePosts(apiData.data.posts.data)
        this.total = apiData.data.posts.meta.pagination.total
      })
    } catch (e) {
      console.error(e)
    }
  },
  fetchOnServer: false,
  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        toggleActions: 'play reset play reverse',
        start: 'top center',
      },
    })
    this.tl.scrollTrigger.disable()

    if (this.$refs.jumbotron) {
      // eslint-disable-next-line no-unused-vars
      const parentSplit = new this.$SplitText(this.$refs.jumbotron, {
        type: 'chars',
        charsClass: 'overflow-hidden',
      })
      const childSplit = new this.$SplitText(this.$refs.jumbotron, { type: 'chars' })

      this.tl.from(childSplit.chars, {
        duration: 0.5,
        xPercent: 100,
        stagger: 0.05,
        onComplete: () => {
          childSplit.revert()
        },
      })
    }

    if (this.$refs.heading) {
      const splitWord = new this.$SplitText(this.$refs.heading, { type: 'chars, words' })
      this.tl.from(splitWord.words, { translateX: 64, opacity: 0, duration: 0.3, stagger: 0.05 }, '<')
    }
    this.$emit('sectionMounted')
  },

  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
    parsePosts(rawPosts) {
      const posts = []
      rawPosts.forEach((p) => {
        posts.push(parsePost(p))
      })
      this.posts = posts
      this.$nextTick(() => {
        // this.$nextTick(() => {
        this.$ScrollTrigger.refresh()
        if (this.$refs.featured) {
          this.tl.from(this.$refs.featured.$el, { translateX: 64, opacity: 0, duration: 0.2 }, 0)
        }
        // })
      })
    },
  },
}
